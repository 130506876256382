<template>
  <div
    class="commonAddressBook"
    v-watermark
  >
    <van-nav-bar
      title="选择人员"
      left-text="返回"
      left-arrow
      fixed
      placeholder
      safe-area-inset-top
      @click-left="$router.back()"
    >
    </van-nav-bar>
    <div class="list">
      <van-checkbox-group
        v-model="result"
        :max="20"
      >
        <van-index-bar :index-list="indexList">
          <div
            v-for="index in indexList"
            :key="index"
          >
            <van-index-anchor :index="index">
              {{ index }}
            </van-index-anchor>
            <van-cell-group>
              <van-cell
                v-for="(item, key) in userAddress[index]"
                clickable
                :key="key"
                :title="item.name"
                @click="toggle('checkboxes' + item.id)"
              >
                <template #icon>
                  <van-checkbox
                    :name="JSON.stringify(item)"
                    :ref="'checkboxes' + item.id"
                  />
                </template>
              </van-cell>
            </van-cell-group>
          </div>
        </van-index-bar>
      </van-checkbox-group>
    </div>
    <div class="commonBox">
      <div class="selectList">
        <span
          v-for="(item, index) in result"
          :key="index"
        >{{ JSON.parse(item).name }}{{ index === result.length - 1 ? ""
          : ";" }}</span>
      </div>
      <van-button
        type="info"
        @click="onClickTest"
        size="small"
      >
        确定{{ result.length > 0 ? "·" + result.length : "" }}
      </van-button>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { getUserAddress } from '@/api/addressBook'

export default {
  name: 'commonAddressBook',
  created() {
    this.result = [...this.selectList]
    this.getUserAddress()
  },
  data() {
    return {

      indexList: [],
      userAddress: {},
      result: []
    }
  },
  computed: {
    ...mapState('addressBook', ['selectList'])
  },
  methods: {
    ...mapMutations('addressBook', ['setSelectList']),
    onClickTest() {
      this.setSelectList(this.result)
      this.$router.back()
    },
    toggle(val) {
      this.$refs[val][0].toggle()
    },
    // 获取人员通讯录
    async getUserAddress() {
      const { data } = await getUserAddress()
      this.indexList = Object.keys(data.data.initials)
      this.userAddress = data.data.initials
    }
  }
}
</script>

<style lang="scss" scoped>
.commonAddressBook {
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  ::v-deep {
    .list {
      height: calc(100% - 50px - 46px);
      overflow: auto;

      .van-cell__title {
        span {
          margin-left: 15px;
        }
      }
    }

    .commonBox {
      height: 50px;
      display: flex;
      width: 100%;
      background-color: rgb(238, 238, 238);
      align-items: center;

      .van-button {
        min-width: 59px;
      }

      .selectList {
        flex: 1;
        height: 100%;
        font-size: 14px;
        line-height: 50px;
        padding-left: 15px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
</style>
